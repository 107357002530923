<script lang="ts" setup>
import { GIcon, type IconName } from '@gem/icons';
import { computed, ref } from 'vue';
import type { BannerProps } from '../types/banner';
import GButton from './GButton.vue';

const props = withDefaults(defineProps<BannerProps>(), {
  type: 'info',
  closable: true,
});
const isLightMode = computed(() => !!props.lightMode);

const isShow = ref(true);

const mapTypeToBackground = {
  info: isLightMode.value ? 'underlay-light-blue' : 'underlay-blue-150',
  success: isLightMode.value ? 'functional-green-100' : 'underlay-green-100',
  warning: isLightMode.value ? 'functional-yellow-100' : 'underlay-yellow-150',
  error: isLightMode.value ? 'functional-red-100' : 'underlay-red-150',
};

const backgroundColor = computed(() => mapTypeToBackground[props.type]);

const mappingIcon = computed(() => {
  switch (props.type) {
    case 'info':
      return 'circle';
    case 'success':
      return 'check';
    case 'warning':
      return 'triangle';
    case 'error':
      return 'error';
    default:
      return 'circle';
  }
});

const iconName = computed(() => `polaris-alert-${mappingIcon.value}` as IconName);

const mappingIconColor = computed(() => {
  switch (props.type) {
    case 'info':
      return 'text-blue-200';
    case 'success':
      return 'text-green-500';
    case 'warning':
      return 'text-yellow-200';
    case 'error':
      return 'text-red-500';
    default:
      return 'text-blue-200';
  }
});

const toggleShow = () => {
  isShow.value = !isShow.value;
};

defineExpose({
  isShow: isShow,
});
</script>
<template>
  <div v-if="isShow" :class="`bg-${backgroundColor}`" class="text-12 text-text-dark-500 flex gap-8 rounded-xl p-8">
    <div class="min-w-[20px]" :class="mappingIconColor"><GIcon :name="iconName" :size="20" /></div>
    <div class="flex flex-col">
      <p v-if="title" class="text-14 font-semibold leading-6">{{ title }}</p>
      <div v-if="message" class="text-12 font-regular leading-5">
        <span v-html="message" />
        <template v-if="link">
          <a :href="link.url" target="_blank" class="text-text-dark-500 underline">{{ link.label }}</a>
        </template>
      </div>
      <GButton v-if="primaryButton" type="tertiary" size="normal" @click="() => primaryButton?.action()">
        {{ primaryButton.label }}
      </GButton>
    </div>
    <div v-if="closable" class="min-w-[28px]">
      <GButton type="ghost" size="normal" only-icon="polaris-x" @click="toggleShow"></GButton>
    </div>
  </div>
</template>
