<script lang="ts" setup>
import { cn } from '@gem/common';
import { GIcon, GSvg } from '@gem/icons';
import { computed } from 'vue';
import { DROPDOWN_MENU_ITEM_STYLE_BY_SIZE, DROPDOWN_MENU_ITEM_STYLE_BY_TYPE } from '../const/dropdownMenu';
import type { GDropdownMenuItemProps } from '../types/dropdownMenu';

const emit = defineEmits<(e: 'click', event: Event, value: GDropdownMenuItemProps['value']) => void>();

const props = withDefaults(defineProps<GDropdownMenuItemProps>(), {
  lightMode: false,
  size: 'medium',
  icon: undefined,
  title: 'item',
  value: 'item',
  active: false,
  disable: false,
  error: false,
  showCheckedIcon: false,
  noSelected: false,
});

const stylesByTypeAndState = computed((): string => {
  const styles = DROPDOWN_MENU_ITEM_STYLE_BY_TYPE;
  const mode = props.lightMode ? 'light' : 'dark';
  if (props.active)
    return props.showCheckedIcon ? cn(styles.activeWithCheckedIcon[mode], styles.hover[mode]) : styles.active[mode];
  if (props.disable) return styles.disabled[mode];
  if (props.error) return styles.error[mode];
  return cn(styles.default[mode], props?.noSelected ? '' : styles.hover[mode]);
});

const stylesBySize = computed((): string =>
  props.size ? DROPDOWN_MENU_ITEM_STYLE_BY_SIZE[props.size] : DROPDOWN_MENU_ITEM_STYLE_BY_SIZE.medium,
);

const iconSizeBySize = computed(() => {
  if (props.size == 'large') return 20;
  if (props.size == 'medium') return 20;
  if (props.size == 'semi-medium') return 16;
  return 16;
});

const handleClick = (e: Event) => {
  !props.disable && emit('click', e, props.value);
};
</script>

<template>
  <div
    class="group flex cursor-pointer items-center gap-12 rounded-xl border"
    :data-active="active"
    :class="cn([stylesByTypeAndState, stylesBySize], svgIcon && active ? 'border-primary-300' : 'border-transparent')"
    @click="handleClick">
    <template v-if="svgIcon">
      <div class="flex items-center gap-8">
        <GSvg :width="64" :height="40" :name="svgIcon" />
        <span class="truncate" v-html="title" />
      </div>
    </template>
    <template v-else>
      <template v-if="showCheckedIcon">
        <div :class="active ? 'visible' : 'invisible'" class="flex items-center text-green-300">
          <GIcon name="polaris-check" :size="iconSizeBySize" />
        </div>
      </template>
      <div class="flex w-full flex-col gap-4">
        <p class="flex w-full items-center justify-between truncate">
          <span class="truncate" v-html="title" />
          <span v-if="showValue" class="text-text-dark-300">{{ displayValue ?? value }}</span>
          <GIcon v-if="icon" :size="iconSizeBySize" :name="icon" />
          <GIcon
            v-if="subTitle && !disableHoverIcon"
            class="invisible group-hover:visible"
            :size="16"
            name="polaris-plus" />
        </p>
        <span v-if="subTitle" class="text-text-dark-100 max-w-[192px]">{{ subTitle }}</span>
      </div>
    </template>
  </div>
</template>
